
/**
 * @roxi/routify 2.18.4
 * File generated Wed Sep 18 2024 23:02:05 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.4"
export const __timestamp = "2024-09-18T23:02:05.215Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isPage": true,
                  "path": "/auth/go/application_password/:service_name",
                  "id": "_auth_go_application_password__service_name",
                  "component": () => import('../src/pages/auth/go/application_password/[service_name].svelte').then(m => m.default)
                }
              ],
              "path": "/auth/go/application_password"
            },
            {
              "isPage": true,
              "path": "/auth/go/code",
              "id": "_auth_go_code",
              "component": () => import('../src/pages/auth/go/code.svelte').then(m => m.default)
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isPage": true,
                  "path": "/auth/go/configure/:service",
                  "id": "_auth_go_configure__service",
                  "component": () => import('../src/pages/auth/go/configure/[service].svelte').then(m => m.default)
                },
                {
                  "isDir": true,
                  "ext": "",
                  "children": [
                    {
                      "isPage": true,
                      "path": "/auth/go/configure/view/:service_name",
                      "id": "_auth_go_configure_view__service_name",
                      "component": () => import('../src/pages/auth/go/configure/view/[service_name].svelte').then(m => m.default)
                    }
                  ],
                  "path": "/auth/go/configure/view"
                }
              ],
              "path": "/auth/go/configure"
            },
            {
              "isPage": true,
              "path": "/auth/go/dash",
              "id": "_auth_go_dash",
              "component": () => import('../src/pages/auth/go/dash.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/auth/go/fail",
              "id": "_auth_go_fail",
              "component": () => import('../src/pages/auth/go/fail.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/auth/go/foundrysignin",
              "id": "_auth_go_foundrysignin",
              "component": () => import('../src/pages/auth/go/foundrysignin.svelte').then(m => m.default)
            }
          ],
          "path": "/auth/go"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/auth/index",
          "id": "_auth_index",
          "component": () => import('../src/pages/auth/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/auth/openid/authorize",
              "id": "_auth_openid_authorize",
              "component": () => import('../src/pages/auth/openid/authorize.svelte').then(m => m.default)
            }
          ],
          "path": "/auth/openid"
        }
      ],
      "path": "/auth"
    },
    {
      "isDir": true,
      "children": [
        {
          "isFallback": true,
          "path": "/go/_fallback",
          "id": "_go__fallback",
          "component": () => import('../src/pages/go/_fallback.svelte').then(m => m.default)
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/go/index",
          "id": "_go_index",
          "component": () => import('../src/pages/go/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/go/guide/index",
              "id": "_go_guide_index",
              "component": () => import('../src/pages/go/guide/index.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/go/guide/rollup",
              "id": "_go_guide_rollup",
              "component": () => import('../src/pages/go/guide/rollup.svelte').then(m => m.default)
            },
            {
              "isPage": true,
              "path": "/go/guide/routify",
              "id": "_go_guide_routify",
              "component": () => import('../src/pages/go/guide/routify.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "ownMeta": {
            "index": 50
          },
          "meta": {
            "index": 50,
            "recursive": true,
            "preload": false,
            "prerender": true
          },
          "path": "/go/guide",
          "id": "_go_guide__layout",
          "component": () => import('../src/pages/go/guide/_layout.svelte').then(m => m.default)
        }
      ],
      "isLayout": true,
      "path": "/go",
      "id": "_go__layout",
      "component": () => import('../src/pages/go/_layout.svelte').then(m => m.default)
    }
  ],
  "path": "/"
}


export const {tree, routes} = buildClientTree(_tree)

